<template>
  <ion-app>
    <div v-if="isLoading">Loading Application</div>
    <ion-split-pane content-id="main-content" v-if="!isLoading && route?.meta?.mode !== 'auth'">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-row
            class="ion-justify-content-between ion-align-items-center user-wrapper"
          >
            <div class="flex">
              <MenuProfileItem v-if="authenicated" @on-close="onClose" />
              <MenuUserLoginItem v-if="!authenicated" @on-close="onClose" />
            </div>
            <ion-menu-toggle>
              <ion-button fill="clear" class="btn-close" color="dark">
                <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-row>
          <ion-list id="inbox-list" class="first-menu-list">
            <ion-menu-toggle
              :auto-hide="false"
              v-for="(p, i) in menu.firstMenu"
              :key="i"
            >
              <ion-item
                v-if="!p.authRequired || authenicated"
                @click="selectedFirstIndex = i"
                router-direction="root"
                :router-link="p.url"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: selectedFirstIndex === i }"
              >
                <i slot="start" :class="'fa-duotone ' + p.icon"></i>
                <ion-label v-if="p.url === '/join-team' && authenicated"
                  >Join A Team</ion-label
                >
                <ion-label v-else>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <hr />
          <ion-list id="labels-list">
            <ion-menu-toggle
              :auto-hide="false"
              v-for="(item, index) in menu.secondMenu"
              :key="index"
            >
              <ion-item
                @click="selectedSecondIndex = index"
                router-direction="root"
                :router-link="item.url"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: selectedSecondIndex === index }"
              >
                <i slot="start" :class="'fa-duotone ' + item.icon"></i>
                <ion-label>{{ item.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <hr />
          <ion-list v-if="authenicated" id="logout-list">
            <ion-menu-toggle :auto-hide="false">
              <ion-item lines="none">
                <ion-button slot="start" fill="clear" @click="logout">
                  <ion-icon
                    slot="start"
                    :src="require('@/assets/icons/logout.svg')"
                  ></ion-icon>
                  <ion-label class="ion-text-uppercase">Log out</ion-label>
                </ion-button>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    <div v-else>
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp,
  IonContent,
  IonRow,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonButton,
  menuController,
  onIonViewDidLeave,
} from "@ionic/vue";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { closeOutline } from "ionicons/icons";
import { useUserStore } from "./store/user.store";
import MenuProfileItem from "./components/MenuProfileItem.vue";
import { MENU_ITEMS } from "./utils/contants";
import MenuUserLoginItem from "./components/MenuUserLoginItem.vue";
import { presentToast } from "./utils/functions";

const isLoading = ref(true);
const selectedFirstIndex = ref<number | null>(0);
const selectedSecondIndex = ref<number | null>(null);
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const menu = MENU_ITEMS;
const authenicated = computed(() => userStore.authenticated);
const token = computed(() => userStore.token);

if (route?.path !== undefined) {
  selectedFirstIndex.value = menu.firstMenu.findIndex(
    (page) => page.url.toLowerCase() === route?.path.toLowerCase()
  );

  selectedSecondIndex.value = menu.secondMenu.findIndex(
    (page) => page.url.toLowerCase() === route?.path.toLowerCase()
  );
}

watch(selectedFirstIndex, (newVal) => {
  if (newVal === 0 || newVal) selectedSecondIndex.value = null;
});
watch(selectedSecondIndex, (newVal) => {
  if (newVal === 0 || newVal) selectedFirstIndex.value = null;
});

const onClose = () => {
  menuController.toggle();
};

const logout = () => {
  userStore.axLogout();
  presentToast("User logged out");
  router.push("/home");
};

onIonViewDidLeave(() => menuController.close("menu"));

onMounted(async () => {
  try {
    await userStore.axGetToken();
    const result = await userStore.axCheckToken(token?.value);
    isLoading.value = false;
    if (!result) {
      // await presentToast("Token is expired.");
    }
  } catch (error) {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped>
@import "./theme/variables/_color";
@import "./theme/variables/_typography";

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --width: 338px;
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 60px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-menu.md ion-item.selected {
  --background: var(--ion-primary-100-color);
  --border-width: 0 0 0 4px;
  --border-color: var(--ion-color-primary);
  --border-style: solid;
  border-radius: 0;
}

// ion-menu.md ion-item.selected ion-icon {
//   color: $primary-400;
// }

// ion-menu.md ion-item ion-icon {
//   color: $primary-400;
// }

// ion-menu.md ion-item ion-label {
//   @include body-semibold;
//   color: $dark-text-100;
// }

ion-menu.ios ion-content {
  --width: 338px;
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 60px;
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
  @include body-semibold;
}

ion-menu.ios ion-item.selected {
  --background: var(--ion-primary-100-color);
  --border-width: 0 0 0 4px;
  --border-color: var(--ion-color-primary);
  --border-style: solid;
  border-radius: 0;
}

// ion-menu.ios ion-item ion-icon {
//   font-size: 24px;
//   color: $primary-400;
// }

// ion-menu.ios ion-item ion-label {
//   @include body-semibold;
//   color: $dark-text-100;
// }

.user-wrapper {
  padding: 0 11px;
  & .menu-user {
    display: block;
    margin-left: 20px;
    & ion-label {
      display: block;
      @include body-semibold;
    }

    & ion-col {
      & ion-text {
        @include details-normal;
        color: $dark-text-67;
        text-align: left;
      }

      & ion-icon {
        width: 8px;
        height: 8px;
      }
    }
  }

  & .btn-close {
    width: 34px;
    height: 34px;
    --padding-start: 0px;
    --padding-end: 0px;
  }
}

.first-menu-list {
  margin-top: 40px;
}

#logout-list {
  ion-button {
    height: 53px;
    ion-icon {
      margin-right: 20px;
    }
  }
}
</style>
